<template>
  <div class="task">
    <van-notice-bar left-icon="volume-o" :text="notice" mode="closeable" />
    <van-dropdown-menu>
      <van-dropdown-item
        v-model="serverId"
        :options="serverList"
        @change="refresh()"
      />
      <van-dropdown-item
        v-model="type"
        :options="typeList"
        @change="refresh()"
      />
      <van-dropdown-item
        v-model="roleIndex"
        :options="roleList"
        @change="refresh()"
      />
    </van-dropdown-menu>
    <div class="container" :style="'height: ' + (clientHeight - 98) + 'px'">
      <van-pull-refresh
        v-model="loading"
        :style="'min-height: ' + (clientHeight - 98) + 'px'"
        @refresh="refresh()"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="暂无数据"
          :immediate-check="false"
          @load="getDataList()"
        >
          <div class="card" v-for="(item, index) in dataList" :key="item.id">
            <div class="top">
              <van-tag :color="getTypeColor(item.type)" size="large">{{
                getTypeName(item.type)
              }}</van-tag>
              <span class="title">{{
                item.serverName +
                "-" +
                $util.hiddenData(item.loginname) +
                "-角色" +
                item.roleIndex
              }}</span>
              <van-checkbox v-model="dataList[index].select"></van-checkbox>
            </div>
            <div class="center">
              <div class="left">
                <div>{{ item.updateTime }}</div>
                <div @click="showLogMessage(item.id)" style="color: blue">
                  {{ "运行信息：" + item.message }}
                </div>
                <div>
                  {{
                    "接单设备：" +
                    (item.listState === -1
                      ? item.device
                        ? item.device
                        : ""
                      : "排队中,前面还有" +
                        (item.listState + 1) +
                        "个任务在等待")
                  }}
                </div>
                <div>{{ "运行时长：" + timeReduce(item.startTime) }}</div>
                <div>
                  {{
                    "累计经验：" +
                    (item.initExp === null || item.currentExp === null
                      ? ""
                      : item.initExp - item.currentExp)
                  }}
                </div>
              </div>
              <div class="right">
                <div class="profile">
                  {{
                    item.level === null || item.roleInfo === null
                      ? ""
                      : item.level + "级" + item.roleInfo
                  }}
                </div>
                <div class="name">
                  {{
                    item.roleName === null
                      ? ""
                      : $util.hiddenData(item.roleName)
                  }}
                </div>
                <div class="operation">
                  <van-button
                    round
                    type="danger"
                    size="mini"
                    @click="restartTask(item.id)"
                    >{{
                      item.device === "任务暂停" ? "恢复" : "暂停"
                    }}</van-button
                  ><van-button
                    round
                    type="danger"
                    size="mini"
                    @click="stopTask(item.id)"
                    >停止</van-button
                  >
                </div>
              </div>
            </div>
            <div class="bottom">
              <van-progress
                :percentage="(item.current / item.total) * 100"
                :pivot-text="item.current * item.scale + ''"
                v-if="item.current > 0"
              />
            </div>
          </div>
          <div class="button" v-if="dataList.length > 0">
            <div>{{ `已选:${this.total}` }}</div>
            <van-button size="small" type="info" @click="select = !select">{{
              select ? "取消选中" : "全部选中"
            }}</van-button>
            <van-button
              size="small"
              :disabled="!select"
              type="danger"
              @click="restartTask()"
              >暂停/恢复</van-button
            >
            <van-button
              size="small"
              :disabled="!select"
              type="danger"
              @click="stopTask()"
              >停止选中</van-button
            >
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //加载状态
      loading: false,
      //是否最后一页
      finished: false,
      //列表数据
      dataList: [],
      //当前分页
      pageIndex: 1,
      //公告
      notice: "",
    };
  },

  created() {
    this.getNoticeList();
  },

  activated() {
    this.refresh();
  },

  computed: {
    serverId: {
      get() {
        return this.$store.state.task.serverId;
      },
      set(val) {
        this.$store.commit("task/updateServerId", val);
      },
    },
    type: {
      get() {
        return this.$store.state.task.type;
      },
      set(val) {
        this.$store.commit("task/updateType", val);
      },
    },
    roleIndex: {
      get() {
        return this.$store.state.task.roleIndex;
      },
      set(val) {
        this.$store.commit("task/updateRoleIndex", val);
      },
    },
    serverList() {
      let list = this.$store.getters.getServerList.map(function (item) {
        return {
          text: item.name,
          value: item.id,
        };
      });
      return [{ text: "不限分区", value: null }].concat(list);
    },
    typeList() {
      return [
        { text: "不限类型", value: null },
        { text: "挖宝", value: 1 },
        { text: "跑环", value: 2 },
        { text: "刷体", value: 3 },
        { text: "升级", value: 4 },
        { text: "邮寄", value: 5 },
        { text: "挂机", value: 6 },
        { text: "环怪", value: 7 },
        { text: "认证", value: 8 },
        { text: "节日", value: 9 },
      ];
    },
    roleList() {
      return [
        { text: "不限角色", value: null },
        { text: "角色1", value: 1 },
        { text: "角色2", value: 2 },
        { text: "角色3", value: 3 },
      ];
    },
    pageSize() {
      return this.$store.state.pageSize;
    },
    //选中个数
    total() {
      return this.dataList.filter((item) => item.select).length;
    },
    //全选状态
    select: {
      get() {
        for (let i = 0; i < this.dataList.length; i++) {
          if (this.dataList[i].select) {
            return true;
          }
        }
        return false;
      },
      set(val) {
        for (let i = 0; i < this.dataList.length; i++) {
          this.dataList[i].select = val;
        }
      },
    },
    clientHeight() {
      return this.$store.state.clientHeight;
    },
    showLog: {
      get() {
        return this.$store.state.showLog;
      },
      set(val) {
        this.$store.commit("setShowLog", val);
      },
    },
    logId: {
      get() {
        return this.$store.state.logId;
      },
      set(val) {
        this.$store.commit("setLogId", val);
      },
    },
  },

  methods: {
    //展示日志信息
    showLogMessage(id) {
      this.logId = id;
      this.showLog = true;
    },
    //获取公告
    async getNoticeList() {
      const { data: res } = await this.$http.get("/notice/get");
      if (res.code === 0) {
        this.notice = res.data.content;
      }
    },
    //获取任务标签颜色
    getTypeColor(type) {
      switch (type) {
        case 1:
          return "#428bca";
        case 2:
          return "#5cb85c";
        case 3:
          return "#E844A7";
        case 4:
          return "#f0ad4e";
        case 5:
          return "#d9534f";
        case 6:
          return "#563D7C";
        case 7:
          return "#283941";
        case 8:
          return "#52D3C7";
        case 9:
          return "#A8C023";
        default:
          return "#FFFFFF";
      }
    },
    //重启任务
    async restartTask(id) {
      let confirm = await this.$dialog.confirm({
        message: "确定暂停/恢复任务?",
      });
      if (confirm) {
        let data;
        if (id) {
          data = [id];
        } else {
          data = this.dataList
            .filter((element) => element.select)
            .map((element) => element.id);
        }
        const { data: res } = await this.$http({
          url: "/script-task/pause",
          method: "post",
          data,
        });
        if (res.code === 0) {
          this.$toast("操作成功");
        }
        setTimeout(() => {
          this.refresh();
        }, 1000);
      }
    },
    //关闭订单
    async stopTask(id) {
      let confirm = await this.$dialog.confirm({
        message: "确定停止任务？",
      });
      if (confirm) {
        let data;
        if (id) {
          data = [id];
        } else {
          data = this.dataList
            .filter((element) => element.select)
            .map((element) => element.id);
        }
        const { data: res } = await this.$http({
          url: "/order/close",
          method: "post",
          data,
        });
        if (res.code === 0) {
          this.$toast("操作成功");
        }
        setTimeout(() => {
          this.refresh();
        }, 1000);
      }
    },
    //获取数据
    async getDataList() {
      this.loading = true;
      const { data: res } = await this.$http({
        url: "/script-task/page",
        method: "get",
        params: {
          page: this.pageIndex,
          limit: this.pageSize,
          serverId: this.serverId,
          type: this.type,
          roleIndex: this.roleIndex,
        },
      });
      if (res.code === 0) {
        this.dataList = this.dataList.concat(
          res.page.list.map((element) => {
            return { ...element, select: false };
          })
        );
        if (this.pageIndex * this.pageSize >= res.page.totalCount) {
          this.finished = true;
        } else {
          this.pageIndex++;
        }
      } else {
        this.finished = true;
      }
      this.loading = false;
    },
    //刷新数据
    refresh() {
      this.pageIndex = 1;
      this.dataList = [];
      this.finished = false;
      this.getDataList();
    },
    // 计算已运行时长
    timeReduce(startTime) {
      if (startTime === null) {
        return "";
      }
      var ms = new Date().getTime() - this.$util.moment(startTime).format("x");
      var hour = Math.floor(ms / 3600000);
      var minute = Math.floor((ms - hour * 3600000) / 60000);
      return (
        (hour > 10 ? hour : "0" + hour) +
        "小时" +
        (minute > 10 ? minute : "0" + minute) +
        "分钟"
      );
    },
  },
};
</script>
<style lang="less" scoped>
.task {
  height: 100%;

  .container {
    overflow-y: scroll;

    .card {
      height: 180px;
      margin: 5px;
      border-radius: 10px;
      background-color: #fff;
      color: #303133;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

      .top {
        height: 40px;

        .van-tag {
          margin-left: 10px;
          margin-right: 10px;
        }

        .title {
          height: 40px;
          font-size: 17px;
          font-weight: 600;
          line-height: 40px;
        }

        .van-checkbox {
          float: right;
          margin-right: 5px;
          margin-top: 5px;
        }
      }

      .center {
        height: 120px;
        display: flex;
        justify-content: space-between;

        .left {
          width: 100%;
          line-height: 24px;
          margin-left: 10px;
          margin-right: 20px;
          margin-top: -5px;
          font-size: 14px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .right {
          width: 72px;
          margin-right: 10px;
          margin-top: -8px;

          .profile {
            width: 72px;
            height: 72px;
            background: #17b3a3;
            border-radius: 36px;
            vertical-align: middle;
            color: white;
            text-align: center;
            line-height: 72px;
            font-size: 15px;
          }

          .name {
            text-align: center;
            font-size: 14px;
            height: 20px;
            line-height: 20px;
          }

          .operation {
            width: 72px;
            display: flex;
            justify-content: space-between;
            margin-top: 5px;
          }
        }
      }

      .bottom {
        height: 20px;
        position: relative;

        .van-progress {
          width: 90%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .button {
      height: 52px;
      padding: 10px 10px;
      display: flex;
      justify-content: space-around;

      div {
        line-height: 32px;
        height: 32px;
        width: 66px;
      }
    }
  }
}
</style>