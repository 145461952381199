import Vue from 'vue';
import { Toast } from 'vant';
import { Button } from 'vant';
import { Image as VanImage } from 'vant';
import { Lazyload } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { Icon } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { Sticky } from 'vant';
import { List } from 'vant';
import { PullRefresh } from 'vant';
import { DropdownMenu, DropdownItem } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Progress } from 'vant';
import { Dialog } from 'vant';
import { Tab, Tabs } from 'vant';
import { NavBar } from 'vant';
import { ActionSheet } from 'vant';
import { Checkbox, CheckboxGroup } from 'vant';
import { Loading } from 'vant';
import { NoticeBar } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Stepper } from 'vant';
import { Picker } from 'vant';
import { SwipeCell } from 'vant';
import { SubmitBar } from 'vant';
import { Tag } from 'vant';
import { Card } from 'vant';
import { Search } from 'vant';
import { Grid, GridItem } from 'vant';
import { Sidebar, SidebarItem } from 'vant';
import { RadioGroup, Radio } from 'vant';

Vue.use(Card);
Vue.use(Tag);
Vue.use(SubmitBar);
Vue.use(SwipeCell);
Vue.use(Picker);
Vue.use(Stepper);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(NoticeBar);
Vue.use(Loading);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(ActionSheet);
Vue.use(NavBar);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Dialog);
Vue.use(Progress);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Sticky);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Toast);
Vue.use(Button);
Vue.use(VanImage);
Vue.use(Lazyload);
Vue.use(Form);
Vue.use(Field);
Vue.use(Icon);
Vue.use(Search);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(Radio);
Vue.use(RadioGroup);