<template>
  <div class="my">
    <van-nav-bar
      :title="title"
      left-text="返回"
      left-arrow
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <router-view :style="`height: ${clientHeight - 46}px;`"></router-view>
  </div>
</template>
<script>
export default {
  computed: {
    title() {
      switch (this.$route.path) {
        case "/my/user-info":
          return "用户信息";
        case "/my/amount":
          return "余额明细";
        case "/my/script-config":
          return "脚本配置";
        case "/my/recharge":
          return "点券充值";
        case "/my/free-account":
          return "永久账号";
        case "/my/ph-price":
          return "跑环物价";
        case "/my/server-goods":
          return "物品统计";
        case "/my/invite-record":
          return "邀请奖励";
        case "/my/device-grant":
          return "设备授权";
        case "/my/double-eleven":
          return "双十一返利";
      }
      if (this.$route.path == "/my/role-goods") {
        return this.$route.params.serverName + "物品信息";
      }
      if (this.$route.path == "/my/mobile-device") {
        return this.$route.params.grantName + "在线设备";
      }
    },
    clientHeight() {
      return this.$store.state.clientHeight;
    },
  },
};
</script>
<style lang="less" scoped>
.my {
  height: 100%;
}
</style>