<template>
  <div class="tools">
    <van-nav-bar
      :title="title"
      left-text="返回"
      left-arrow
      @click-left="back()"
      @click-right="share()"
    >
      <template #right>
        <van-icon name="share-o" size="24" />
      </template>
    </van-nav-bar>
    <div :style="`height: ${clientHeight - 46}px;`">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    title() {
      switch (this.$route.path) {
        case "/tools/xinfa-calc":
          return "心法计算";
        case "/tools/horse-calc":
          return "坐骑计算";
        case "/tools/tianfu-calc":
          return "天赋计算";
        case "/tools/role-calc":
          return "角色计算";
        case "/tools/ph-needs":
          return "跑环物品";
        case "/tools/public-monster":
          return "怪物查询";
        case "/tools/st-hot":
          return "刷体热力";
        case "/tools/fee-price":
          return "刷体热力";
      }
    },
    clientHeight() {
      return this.$store.state.clientHeight;
    },
  },

  methods: {
    share() {
      this.$copyText(window.location.href).then(
        (e) => {
          this.$toast("地址已复制");
        },
        (e) => {
          this.$toast("复制地址失败");
        }
      );
    },
    back() {
      if (this.$router.history.prev) {
        this.$router.back();
      } else {
        this.$router.push({ name: "tools-nav" });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.tools {
  height: 100%;
}
</style>