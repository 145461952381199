<template>
  <div class="login">
    <div class="title">登录</div>
    <van-form @submit="submit" label-width="3em" label-align="center">
      <van-field v-model="mobile" placeholder="请输入手机号" label="手机号" />
      <van-field
        v-model="password"
        :type="passwordType"
        placeholder="请输入登录密码"
        :label="`密${'\u3000'}码`"
      >
        <template slot="right-icon">
          <span class="solts" @click="switchPasswordType">
            <van-icon name="closed-eye" v-if="passwordType === 'password'" />
            <van-icon name="eye" v-else />
          </span>
        </template>
      </van-field>
      <van-field
        v-model="captcha"
        placeholder="请输入右图验证码"
        label="验证码"
      >
        <template #button>
          <van-image
            fit="contain"
            :src="url"
            style="height: 30px; margin: -10px 0"
            @click="changeCaptcha()"
          />
        </template>
      </van-field>
      <van-button round block type="info" native-type="submit">登录</van-button>
    </van-form>
    <div class="bottom">
      <span @click="$router.push({ name: 'register-mobile' })">注册账号</span>
      <!-- <span
        @click="
          $router.push({ name: 'contact', params: { backName: 'login' } })
        "
        >交流群聊</span
      > -->
      <span @click="$router.push({ name: 'reset-mobile' })">找回密码</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      mobile: "",
      password: "",
      captcha: "",
      uuid: "",
      passwordType: "password",
    };
  },

  computed: {
    url() {
      return this.$http.defaults.baseURL + "/sys/captcha?uuid=" + this.uuid;
    },
  },

  mounted() {
    this.changeCaptcha();
  },

  methods: {
    //登录
    async submit() {
      if (!this.$util.isMobile(this.mobile)) {
        this.$toast("手机号格式不正确");
        return;
      }
      if (!this.$util.isPassword(this.password)) {
        this.$toast("密码只能输入6-20位非空白字符");
        return;
      }
      if (!this.$util.isCaptcha(this.captcha)) {
        this.$toast("验证码格式不正确");
        return;
      }
      const { data: res } = await this.$http.post("/sys/login", {
        mobile: this.mobile,
        password: this.password,
        captcha: this.captcha,
        uuid: this.uuid,
      });
      if (res.code === 0) {
        //登录成功
        this.$toast("登录成功");
        window.localStorage.setItem("token", res.token);
        this.$store.dispatch("updateUser");
        this.$router.push({ name: "main" });
      } else if (res.code === 10007) {
        //验证码错误
        this.changeCaptcha();
      }
    },
    //更换验证码
    changeCaptcha() {
      this.uuid = this.$util.uuid();
    },
    //切换密码显示
    switchPasswordType() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
  },
};
</script>
<style lang="less" scoped>
.login {
  width: 85%;
  height: 280px;
  padding: 20px 0;
  border-radius: 10px;
  box-shadow: 2px 2px 5px;
  background: white;

  .title {
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }

  .van-form {
    width: 100%;

    .van-button {
      margin: 20px auto;
      width: 80%;
    }
  }

  .bottom {
    width: 100%;
    margin-top: 30px;
    font-size: 12px;
    color: blue;
    display: flex;
    justify-content: space-between;
  }
}
</style>