<template>
  <div class="index">
    <div class="title">逆流云挂机</div>
    <router-view></router-view>
    <!-- 底部 -->
    <!-- <div class="bottom">
      <a href="https://beian.miit.gov.cn/" target="_blank"
        >桂ICP备2022005054号-1</a
      >
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
<style lang="less" scoped>
.index {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    font-size: 20px;
    font-weight: 600;
    margin-top: -30px;
    margin-bottom: 30px;
  }

  .bottom {
    position: fixed;
    bottom: 10px;
    font-size: 14px;
  }
}
</style>