import Vue from 'vue'
import Vuex from 'vuex'
import http from '@/plugins/axios'
import task from './modules/task'
import account from './modules/account'
//import createPersistedstate from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    task,
    account
  },
  state: {
    // 分页设置
    pageSize: 20,
    // 用户信息
    user: '{}',
    // 游戏分区列表
    serverList: '[]',
    // 脚本配置列表
    configList: '[]',
    //视口高度
    clientHeight: 0,
    //展示日志弹窗
    showLog: false,
    //日志id
    logId: null
  },
  getters: {
    getUser(state) {
      return JSON.parse(state.user);
    },
    getServerList(state) {
      return JSON.parse(state.serverList);
    },
    getConfigList(state) {
      return JSON.parse(state.configList);
    },
  },
  mutations: {
    setUser(state, val) {
      state.user = JSON.stringify(val);
    },
    setServerList(state, val) {
      state.serverList = JSON.stringify(val);
    },
    setConfigList(state, val) {
      state.configList = JSON.stringify(val);
    },
    setClientHeight(state, val) {
      state.clientHeight = val;
    },
    setShowLog(state, val) {
      state.showLog = val;
    },
    setLogId(state, val) {
      state.logId = val;
    }
  },
  actions: {
    async updateUser(context) {
      const { data: res } = await http.get('/user/info');
      if (res.code === 0) {
        context.commit("setUser", res.data);
      }
    },
    async updateServerList(context) {
      const { data: res } = await http.get('/public/server-list');
      if (res.code === 0) {
        context.commit("setServerList", res.data);
      }
    },
    async updateConfigList(context) {
      const { data: res } = await http.get('/script-config/page?page=1&limit=500');
      if (res.code === 0) {
        context.commit("setConfigList", res.page.list);
      }
    }
  },
  // plugins: [
  //   createPersistedstate({
  //     key: 'config',
  //     storage: window.localStorage
  //   })
  // ]
})
