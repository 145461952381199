import moment from 'moment';
import { v4 } from 'uuid';
import md5 from 'js-md5';

//指定moment语言时区
moment.locale('zh-cn');

export default {
  md5,
  moment,
  uuid: v4,
  isNullOrEmpty: function (params) {
    switch (typeof params) {
      case 'number':
        return params === NaN ? true : false;
      case 'string':
        return params.length === 0 ? true : false;
      case 'object':
        if (Array.isArray(params)) {
          return params.length === 0 ? true : false;
        } else {
          return params === null ? true : false;
        }
      case 'undefined':
        return true;
      default:
        return false;
    }
  },
  formatTime(time) {
    return moment(time).format('YYYY-MM-DD  HH:mm');
  },
  getCurrentDayHourTime(int) {
    let date = moment();
    date.set("hour", int);
    date.set("minute", 0);
    date.set("second", 0);
    date.set("millisecond", 0);
    return date;
  },
  isEmail: function (s) {
    return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s);
  },
  isUrl: function (s) {
    return /^http[s]?:\/\/.*/.test(s);
  },
  isMobile: function (s) {
    return /^1[3-9]\d{9}$/.test(s);
  },
  isCaptcha: function (s) {
    return /^[a-zA-Z0-9]{5}$/.test(s);
  },
  isPassword: function (s) {
    return /^\S{6,20}$/.test(s);
  },
  hiddenData: function (data) {
    let str = data + '';
    if (str.length === 0) {
      return '';
    } else if (str.length === 1) {
      return '*';
    } else if (str.length === 2) {
      return '*' + str.slice(1, 2);
    } else if (str.length === 3) {
      return str.slice(0, 1) + '*' + str.slice(2, 3);
    } else if (str.length === 4) {
      return str.slice(0, 1) + '**' + str.slice(3, 4);
    } else if (str.length === 5) {
      return str.slice(0, 1) + '**' + str.slice(3, 5);
    } else if (str.length === 6) {
      return str.slice(0, 2) + '**' + str.slice(4, 6);
    } else if (str.length === 7) {
      return str.slice(0, 2) + '***' + str.slice(5, 7);
    } else if (str.length === 8) {
      return str.slice(0, 2) + '****' + str.slice(6, 8);
    } else {
      if (str.length % 2 === 0) {
        let number = (str.length - 4) / 2;
        return str.slice(0, number) + '****' + str.slice(str.length - number, str.length);
      } else {
        let number = (str.length - 3) / 2;
        return str.slice(0, number - 1) + '****' + str.slice(str.length - number, str.length);
      }
    }
  },
  getDataProxy(data, _this) {
    return new Proxy(data, {
      set(obj, name, val) {
        _this.$emit("input", {
          ...obj,
          [name]: val,
        });
        return true;
      },
    });
  }
};
