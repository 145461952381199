import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/base.css';
import '@/plugins/vant';
import http from '@/plugins/axios';
import util from '@/plugins/util';
import VueClipBoard from 'vue-clipboard2';

Vue.use(VueClipBoard);
Vue.prototype.$http = http;
Vue.prototype.$util = util;

Vue.prototype.getTypeName = (val) => {
  switch (val) {
    case 1:
      return '挖宝'
    case 2:
      return '跑环'
    case 3:
      return '刷体'
    case 4:
      return '升级'
    case 5:
      return '邮寄'
    case 6:
      return '挂机'
    case 7:
      return '环怪'
    case 8:
      return '认证'
    case 9:
      return '节日'
    default:
      return ''
  }
};

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
