export default {
  namespaced: true,
  state: {
    serverId: null,
    orderType: 1,
    orderMode: 0,
    roleIndex: null,
    configId: null
  },
  mutations: {
    updateServerId(state, value) {
      state.serverId = value;
    },
    updateOrderType(state, value) {
      state.orderType = value;
    },
    updateOrderMode(state, value) {
      state.orderMode = value;
    },
    updateRoleIndex(state, value) {
      state.roleIndex = value;
    },
    updateConfigId(state, value) {
      state.configId = value;
    },
  }
}

