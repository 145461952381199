export default {
  namespaced: true,
  state: {
    serverId: null,
    type: null,
    roleIndex: null,
  },
  mutations: {
    updateServerId(state, value) {
      state.serverId = value;
    },
    updateType(state, value) {
      state.type = value
    },
    updateRoleIndex(state, value) {
      state.roleIndex = value
    }
  }
}
