<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created() {
    this.checkVersion();
    if (localStorage.getItem("version") != process.env.VUE_APP_VERSION) {
      this.$dialog({
        title: "网站已完成更新",
        messageAlign: "center",
      }).then(() => {
        localStorage.setItem("version", process.env.VUE_APP_VERSION);
      });
    }
  },

  mounted() {
    this.resetClientHeight();
  },

  methods: {
    //重置视口高度
    resetClientHeight() {
      this.clientHeight = document.documentElement["clientHeight"];
      window.onresize = () => {
        this.clientHeight = document.documentElement["clientHeight"];
      };
    },
    //检测前端版本是否有更新
    async checkVersion() {
      const { data: res } = await this.$http.get("/sys/font-version");
      if (res && res.code === 0) {
        if (
          process.env.VUE_APP_VERSION != res.data &&
          process.env.NODE_ENV === "production"
        ) {
          this.$dialog
            .alert({
              message: "网站内容有更新,请刷新网页",
            })
            .then(() => {
              location.reload();
            });
        }
      }
    },
  },
  computed: {
    clientHeight: {
      get() {
        return this.$store.state.clientHeight;
      },
      set(val) {
        this.$store.commit("setClientHeight", val);
      },
    },
  },
};
</script>
<style>
:root {
  --bg-color: #f7f8fa;
}
#app {
  background: var(--bg-color);
}
</style>

